import React from 'react'
import { css } from '@emotion/core'
// import Grid from '@material-ui/core/Grid'
// import theme from '../../config/theme'

export default ({ children }) => (
  <blockquote
    css={css`
      font-size: 140%;
      border: 0;
      margin-left: 10% !important;
      margin-right: 10% !important;
      margin-top: 1.5em;
      margin-bottom: 1.5em;
      padding: 0 !important;
      font-style: normal;
      color: rgba(0,0,0,.65);
    `}
  >
    {children}
  </blockquote>
)
